"use client";
import React, { useEffect, useRef } from "react";
import { Container } from "@chakra-ui/react";
import Script from "next/script";

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    Trustpilot: any;
  }
}

const TrustPilotEmbed = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined" && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <Container maxW="container.xl" mt="63px">
      <Script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="6571ea6ee585f90503de1860"
        data-style-height="140px"
        data-style-width="100%"
        data-theme="light"
        data-stars="5"
        data-review-languages="en"
      >
        <a
          href="https://www.trustpilot.com/review/example.com"
          target="_blank"
          rel="noopener"
        >
          {" "}
          Trustpilot
        </a>
      </div>
    </Container>
  );
};

export default TrustPilotEmbed;
