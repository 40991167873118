"use client";

import { Link } from "@chakra-ui/next-js";
import { Button, HStack } from "@chakra-ui/react";
// import { FaArrowRight } from "react-icons/fa6";

const EstimateSectionLinks = () => (
  <HStack mt="42px">
    <Button
      as={Link}
      href="#top"
      borderRadius="4px"
      bg="brand.secondary"
      py="16px"
      px="24px"
      h="50px"
      color="white"
      fontSize="14px"
      fontWeight="700"
      variant="primary"
    >
      Get Started
    </Button>
    {/* <Button
      as={Link}
      href="/about"
      h="50px"
      variant="ghost"
      rightIcon={<FaArrowRight />}
      _hover={{
        bg: "transparent",
        textDecoration: "none",
      }}
    >
      Learn More
    </Button> */}
  </HStack>
);

export default EstimateSectionLinks;
