"use client";

import { Image } from "@chakra-ui/next-js";

const HERO_IMG_WIDTH = 260;

const HeroSectionLogo = ({
  file,
}: {
  file: {
    url: string;
    details: {
      image: {
        height: number;
        width: number;
      };
    };
    fileName: string;
  };
}) => {
  return (
    <Image
      src={`https://${file.url}`}
      width={HERO_IMG_WIDTH}
      height={
        (file.details.image.height / file.details.image.width) * HERO_IMG_WIDTH
      }
      alt={file.fileName}
      mb="20px"
      zIndex="100"
    />
  );
};

export default HeroSectionLogo;
